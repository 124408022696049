import * as IS from "./validator";

/**
 * Execute an HTTPS REQUEST to backend, accepted params are:
 * - String "noAuth" if given perform request without token login
 * - String "noRedirect" if given avoid redirect to Login on status 401
 * - String ("POST", "GET", "PUT", "DELETE") set the request method, default POST
 * - String ("json", "blob", "text") set the return value type, default json
 * - Object define the body of request
 * - Object.headers define the headers
 * @param {string} api - API path 
 *  
 */
export function request(api) {
    return new Promise(async (resolve, reject) => {
        try {
            const { protocol, hostname, port } = window.location;
            let auth = true;
            let redirect = true;
            let method = "json";
            let token = localStorage.getItem("token");
            let options = {
                method: "POST",
                headers: {},
                body: {}
            }
            if (arguments.length > 1) {
                for (let i=1; i<arguments.length; i++) {
                    if (IS.formData(arguments[i])) options.body = arguments[i];
                    else if (IS.string(arguments[i])) {
                        if (arguments[i] === "noAuth") auth = false;
                        else if (arguments[i] === "noRedirect") redirect = false;
                        else if (["json", "blob", "text"].includes(arguments[i])) method = arguments[i];
                        else if (["POST", "GET", "PUT", "DELETE"].includes(arguments[i])) options.method = arguments[i];
                        else token = arguments[i];
                    }  else if (IS.object(arguments[i])) {
                        if (IS.object(arguments[i].headers)) options.headers = { ...options.headers, ...arguments[i].headers };
                        else options.body = { ...options.body, ...arguments[i] };
                    }
                }
            }
            if (auth) {
                if (IS.string(token)) options.headers["Authorization"] = `Bearer ${token}`;
                else if (redirect) goUrl("login");
            }
            if (!IS.formData(options.body) && method === "json") options.headers["Content-Type"] = "application/json";
            if (options.method === "GET") delete options.body;
            else if (!IS.formData(options.body)) options.body = JSON.stringify(options.body);
            let url = `${protocol}//${hostname}:${port}/${api}`;
            if (IS.url(api)) url = api;
            const r = await fetch(url, options); 
            if (auth && r.status === 401 && redirect) {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("comuni");
                goUrl("login");                
            } else if (r.headers.get("Content-Type") && (r.headers.get("Content-Type").includes("text/html"))) {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, html: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (r.headers.get("Content-Type") && (r.headers.get("Content-Type").includes("text/plain"))) {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, result: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (r.ok && (method === "blob")) {
                try {
                    const result = await r.blob();                        
                    const objectURL = URL.createObjectURL(result);
                    resolve({ statusCode: r.status, objectURL: objectURL }); 
                } catch(e) {
                    reject(new Error(e.message));
                } 
            } else if (method === "text") {
                try {
                    const result = await r.text();                   
                    resolve({ statusCode: r.status, result: result }); 
                } catch(e) {
                    reject(new Error(e.message));
                }  
            } else if (method === "json") {
                try {
                    const json = await r.json();
                    resolve({ statusCode: r.status, ...json }); 
                } catch(e) {
                    reject(new Error(e.message));
                }                             
            } else {
                resolve({ statusCode: r.status, result: r.statusText });
            }                 
        } catch(e) {
            reject(new Error(e.message));
        }
    })    
}

export const bounce = (event) => {
    const { target } = event;
    target.classList.add("bounceButton");
    target.addEventListener("animationend", () => { target.classList.remove("bounceButton") });
}
/**
 * 
 * @param {string} url - If complete url go there, if void same page, else backend + url
 * @param {boolean} [blank] - True to Open new window 
 */
export const goUrl = (url, blank) => {
    try {
        const { protocol, hostname, port, pathname } = window.location;
        let dest = `${protocol}//${hostname}:${port}`;
        if (url && isValidUrl(url)) dest = url;
        else if (url && (typeof url === "string")) {
            if (url[0] === "/") dest = dest + url;
            else dest = `${dest}/${url}`;
        } else dest = dest + pathname;        
        if (blank) window.open(dest, "_blank");
        else window.location.href = dest;
    } catch(e) {
        console.log(e);
    }
}

export function validateIP(ipaddress) {  
    if (!ipaddress || (typeof ipaddress !== "string")) return(false);
    else if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) 
        return(true);  
    else if (ipaddress.includes(".") && (ipaddress.split(".").length >= 3)) return(true);
    else return (false)  
}  

export function checkPassword(event) {
    const { value } = event.target;
    const passwordStrength = {
        length: (value.length >= 10),
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        special: /[?!@#$%^&*)(+=._-]/.test(value),
        number: /[0-9]/.test(value)
    }
    return passwordStrength;
}

export function isSuperRole() {
    const role = localStorage.getItem("role");
    if (IS.string(role) && ["admin", "it"].includes(role)) return true;
    else return false;
}

export function createdValues() {
    const user = localStorage.getItem("user");
    const date = new Date().toISOString();
    return { createdBy: user, createdTime: date };
}

export function sleep(ms) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    })
}

export function validateEmail(email) {
    try {
        const splitted = email.split("@");
        if (splitted.length === 2) {
            if (splitted[1].includes(".")) return (true);
            else return (false);
        } else return (false);
    } catch(err) {
        return (false);
    }
}

export function isValidUrl(string) {
    try { 
        return Boolean(new URL(string)); 
    } catch(e){ 
        return false; 
    }
}
/**
 * 
 * @param {string} url - If complete url go there, if void same page, else backend + url
 * @returns {string} - The URL string
 */
export function createURL(url) {
    try {
        const { protocol, hostname, port, pathname } = window.location;
        let dest = `${protocol}//${hostname}:${port}`;
        if (url && isValidUrl(url)) dest = url;
        else if (url && (typeof url === "string")) {
            if (url[0] === "/") dest = dest + url;
            else dest = `${dest}/${url}`;
        } else dest = dest + pathname;
        return dest;     
    } catch(e) {
        return null;
    }
}

export function printDate(d) {
    try {
        let data = new Date();
        let options = {};
        let locale = document.documentElement.lang;
        if (IS.date(d) || IS.string(d)) data = new Date(d);
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.object(arguments[i])) options = { ...options, ...arguments[i] };
                else if (IS.string(arguments[i])) locale = arguments[i];
            }
        } 
        return data.toLocaleString(locale, options);
    } catch(e) {
        console.log(e);
        return e.message;
    }
}
/**
 * Function that return File from ObjectURL
 * @param {string} url 
 * @returns 
 */
export function fileFromURL(url) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!IS.string(url)) reject(new Error(`param url must be a string`));
            else {
                const r = await fetch(url);
                if (r.ok) {
                    const file = await r.blob();
                    resolve(file); 
                } else reject(new Error(r.text()));
            }
        } catch(e) {
            reject(new Error(e.message));
        }
    })
}
/**
 * Download given objectURL
 * @param {string} objectURL - ObjectURL to download
 * @param {string} [name] - If given set download name 
 */
export function downloadObjectURL(objectURL, name) {
    if (IS.objecturl(objectURL)) {          
        const a = document.createElement("a");
        a.setAttribute("href", objectURL);
        a.setAttribute("download", IS.string(name) ? name : "download");
        a.style.display = "none";     
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }          
}

export function readLocalStorage(key) {
    try {
        const val = localStorage.getItem(key);
        if (IS.string(val)) {
            try {
                const json = JSON.parse(val);
                return json;
            } catch(e) {
                return val;
            }
        } else return val;
    } catch(e) {
        return null;
    }
}

export function listComuni() {
    return new Promise(async (resolve, reject) => {
        try {
            const r = await request("public/listComuni");
            if (r.statusCode === 200) {
                let arr = Array.from(r.body);
                arr = arr.map(e => e.nome);
                arr.sort();
                resolve(arr);
            } else if (r.statusCode === 202) resolve([]);
            else reject(new Error(r.result));
        } catch(e) {
            reject(new Error(e.message));
        }
    })
}

export function loadQueryParams() {  
    try {     
        const queryParams = new URLSearchParams(window.location.search);
        const o = Object.fromEntries(queryParams.entries());
        if (location.href.includes("?")) history.pushState({}, null, location.href.split("?")[0]);
        return o;             
    } catch(e) {
        return { result: e.message };
    }  
}

export function createQueryParams(obj) {
    try {
        const queryString = new URLSearchParams(obj).toString();
        return queryString;
    } catch(e) {
        return null;
    }
}

/**
 * Create a new URL and redirect to it
 * - URL String: set the baseUrl, default current page
 * - String: used to build new URL from baseUrl, use "/" for root path
 * - blank String: set new page _blank redirect
 * - Object: Build query Params values
 */
export function redirect() {
    try {
        let url = new URL(window.location.href);
        let blank = false;
        if (arguments.length) {
            for (let i=0; i<arguments.length; i++) {
                if (IS.url(arguments[i])) url = new URL(arguments[i]);
                else if (IS.object(arguments[i], true)) {
                    for (const [k,v] of Object.entries(arguments[i])) {
                        try {
                            url.searchParams.append(k,v);
                        } catch(e) {
                            console.log(e);
                        }
                    }
                }
                else if (IS.string(arguments[i]) && ["blank", "_blank"].includes(arguments[i])) blank = true;
                else if (IS.string(arguments[i], true)) url = new URL(arguments[i], url);
            }
        } 
        if (blank) window.open(url);
        else window.location.replace(url);
    } catch(e) {
        console.log(e);
    }
}
