import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Clock from "./elements/clock";
import ErrorElement from "./elements/errorelement";
import { NoItems } from "./elements/fields";

import './styles/main.css';

const Load = () => {
  return (
    <h1>... LOADING ROUTERS ...</h1>
  )
}

const Login = React.lazy(() => import("./Login"));
const Admin = React.lazy(() => import("./Admin"));
const Tickets = React.lazy(() => import("./Tickets"));

class App extends React.Component {

  render() {
    return (    
      <BrowserRouter>
          <Clock />
            <Routes>
              <Route path="/" element={
                <ErrorElement>
                  <Suspense fallback={<Load />}>
                    <Admin />
                  </Suspense>
                </ErrorElement>
              }/>
              <Route path="/tickets" element={
                <ErrorElement>
                  <Suspense fallback={<Load />}>
                    <Tickets />
                  </Suspense>
                </ErrorElement>
              }/>
              <Route path="/login" element={
                <ErrorElement>
                  <Suspense fallback={<Load />}>
                    <Login />
                  </Suspense>
                </ErrorElement>
              }/>
              <Route path="*" element={<NoItems text="... PATH NOT FOUND ..." />} />
            </Routes>
      </BrowserRouter>
    )  
  }
}

export default App;
